import React, { useState, useEffect } from 'react';
import {Container} from "react-bootstrap";
import Quote from "../components/Quote";
import Sunday from "../components/Sunday";
import News from "../components/News";
import Stream from '../components/Stream';

const Church = () => {
    const [hasStream, setHasStream] = useState(false);
    const [liveVideo, setLiveVideo] = useState(null);
    const [streamUrl,setStreamUrl] = useState('https://stream.novoe-serdce.ru/hls/stream.m3u8');
    const youtubeEndpointLive = 'https://test.silenc.ru/youtoo/';
    const fallbackEndpoint = 'https://test.silenc.ru/last/';

    useEffect(() => {
        const checkStream = async () => {
            try {
                const response = await fetch(streamUrl, {
                    method: 'HEAD', // Используем HEAD запрос, чтобы не загружать весь файл
                });
                if (response.ok) {
                    setHasStream(true);
                } else {
                    setHasStream(true);
                    setStreamUrl('https://stream.novoe-serdce.ru/api/recordings/stream_13_10_2024/index.m3u8');
                }
            } catch (error) {
                console.error('Ошибка при проверке потока:', error);
                setHasStream(false);
            }
        };

        checkStream();
    }, []);

    useEffect(() => {
        // Получение данных о текущем видео
        const fetchLiveVideo = async () => {
            try {
                let response = await fetch(youtubeEndpointLive);
                let data = await response.json();

                // Если первый эндпоинт возвращает пустой словарь, используем второй эндпоинт
                if (Object.keys(data).length === 0) {
                    response = await fetch(fallbackEndpoint);
                    data = await response.json();
                }

                if (data && data.id && data.id.videoId) {
                    setLiveVideo(data);
                } else {
                    setLiveVideo(null); // Убедитесь, что нет данных, которые можно отобразить
                }
            } catch (error) {
                console.error('Ошибка при получении данных о видео:', error);
                setLiveVideo(null);
            }
        };

        fetchLiveVideo();
    }, []);

    return (
        <Container>
            <div className="d-flex flex-column mb-3 align-items-center align-content-md-center">
                <div className="p-2">
                    <Quote/>
                </div>
                <div className="p-2">
                    {hasStream ? (
                        <Stream />
                    ) : liveVideo ? (
                        <div>
                            <h3>Youtube</h3>
                            <a
                                href={`https://www.youtube.com/watch?v=${liveVideo.id.videoId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {liveVideo.snippet.title}
                            </a>
                        </div>
                    ) : (
                        <Sunday />
                    )}
                </div>
                <div className="p-2">
                    <News/>
                </div>
            </div>
        </Container>
    );
};

export default Church;